import React, { useMemo } from "react"
import { Link } from "gatsby"
import cx from "classnames"

import SimpleP from "../../components/block-content/simpleP"
import YoutubeEmbed from "../../components/youtubeEmbed"
import TestimonialSlider from "./testimonialSlider"
import Figure from "../figure"

import styles from "./heroSection.module.scss"

const HeroSection = props => {
  const embedId = useMemo(() => {
    if (!props.link) return null
    const youtubeUrl = new URL(props.link)
    return youtubeUrl.searchParams.get("v")
  }, [props.link])

  const isHeading =
    !!props.heading || !!props.tagline || embedId || !!props.image
  const isProducts = !!props.productsBanner?.products?.length
  const isTestimonial = !!props.testimonialSection?.testimonials?.length

  const heroStyles = cx(styles.hero, {
    [styles.hero__noHeading]: !isHeading,
    [styles.hero__testimonial]: isTestimonial,
  })

  return (
    <section className={heroStyles}>
      <div className={styles.heroContent}>
        {isHeading && (
          <div className={styles.heroHeading}>
            <div className={styles.heroTitles}>
              <h1>{props.heading}</h1>
              {props.tagline && <SimpleP blocks={props.tagline} />}
            </div>

            {embedId ? (
              <YoutubeEmbed
                embedId={embedId}
                previewAsset={props.videoFile.asset}
              />
            ) : (
              <Figure {...props.image} />
            )}
          </div>
        )}

        {isProducts && (
          <div className={styles.heroProducts}>
            {props.productsBanner.products.map(product => (
              <Link
                key={product._id}
                to={`/products/${product.slug}/`}
                className={styles.heroProductItem}
                style={{ alignContent: "center" }}
              >
                <span>{product.title}</span>
              </Link>
            ))}
          </div>
        )}

        {isTestimonial && <TestimonialSlider {...props.testimonialSection} />}
      </div>
    </section>
  )
}

export default HeroSection
